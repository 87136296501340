<script setup lang="ts">
import type { InitDataResponseData } from "@/types";

const props = defineProps<{
	data: InitDataResponseData;
}>();

const { isMobile } = useDevice();
const { data: bonusesData } = useGetBonusesData();
const isDailyWheel = computed(
	() =>
		!props.data?.isGuest &&
		(bonusesData.value?.dailyWheel?.available || bonusesData.value?.dailyWheel?.reasonType === "phone")
);
</script>

<template>
	<div class="widgets-fixed-side">
		<MDailyWheelWidget v-if="isDailyWheel" />
		<template v-if="isMobile">
			<ClientOnly>
				<ONotificationCenterBox class="notification-center out-of-menu" />
			</ClientOnly>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.widgets-fixed-side {
	position: fixed;
	z-index: 20;
	right: 0;
	bottom: 158px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 16px;
	@include media-breakpoint-down(lg) {
		@media (orientation: landscape) {
			bottom: 104px;
		}
	}
	@include media-breakpoint-up(lg) {
		bottom: 25vh;
		& > * {
			cursor: pointer;
		}
	}
}
</style>
